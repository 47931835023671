import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "slug": "jobs",
  "title": "Lavora con noi",
  "pretitle": "invia la tua candidatura",
  "image": "../images/contract.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Vorresti lavorare in un'azienda giovane e compatitiva?`}</p>
    <p>{`Inviaci ora il tuo curriculum e ti contatteremo al più presto!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      